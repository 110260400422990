<header class="wgc-main-header">
  <div class="container">
    <div class="wgcTitle">
      <a href="index.html">
        <img src="assets/images/LogoBlack_SVG.svg" alt="webgate logo" class="wgcLogo" />
        <h1>Spesen App</h1>
      </a>
    </div>
    <div class="wgcHeaderRight"></div>
  </div>
</header>

<mat-toolbar class="wgc-navbar">
  <mat-toolbar-row>
    <div class="container">
      <button (click)="toggleSidenav(true)" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <span>WGC Spesen</span>
      <div class="wgc-navbar-right">
        <button mat-button color="primary" routerLink="/new">Neue Erfassung</button>
        <button mat-icon-button>
          <mat-icon [matMenuTriggerFor]="menu">account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item disabled *ngIf="(authStat|async) == 1">
            <mat-icon>account_circle</mat-icon>
            <span>{{userName|async}}</span>
          </button>
          <button mat-menu-item *ngIf="(authStat|async) == 0" (click)="login()">
            <mat-icon>voicemail</mat-icon>
            <span>Anmelden</span>
          </button>
          <button mat-menu-item *ngIf="(authStat|async) == 1" (click)="logout()">
            <mat-icon>notifications_off</mat-icon>
            <span>Abmelden</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
