import { HttpClient, HttpEventType, HttpProgressEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";


@Injectable({
  providedIn:"root"
})
export class UtilService{
  constructor(
    private http:HttpClient,
  ){}
  uploadFiles(files:[url:string,blob:Blob][]){
    const obs:Observable<HttpProgressEvent>[]=files.map(([url,f]) => this.http.put(url, f,{reportProgress:true,observe:"events"})
        .pipe(filter(v=>v.type==HttpEventType.UploadProgress))) as any
      return combineLatest(obs)
        .pipe(map((v)=>v
          .map(v=>v.loaded/v.total!)
          .reduce((o,c)=>o+c,0)/v.length))
  }
  hashFilesBackground(files:File[]):Promise<string[]>{
    const d=Number(new Date())
    return Promise.resolve(files.map((_,i)=>String(d+i)))
  }
}
