import { Injectable } from "@angular/core";
import { defer, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Api, ExpenseType, Metadata, Replace } from "../../backendTypes";
import { ApiGatewayClient } from "../httpClients/apiGateway.client";
import { UserService } from "./user.service";
import { UtilService } from "./util.service";


@Injectable({
  providedIn:"root"
})
export class AdminService{
  constructor(
    private api:ApiGatewayClient,
    private us:UserService,
    private util:UtilService
  ){}
  listExpenses(){
    return this.api.get<Api.listExpenses.Res>("admin/expense")
      .pipe(switchMap(async w=>Promise.all(w.map(async v=>({
        ...v,
        user:await this.us.getUser(v.user)
      })))))
  }
  createExpense({user, ...exp}: Omit<Api.putExpense.Req, 'attachments'>&{user:string}, files: File[]):Observable<{progress:number,id:string}> {
    return defer(async () => {
      const hashes=await this.util.hashFilesBackground(files)
      const attachments:Record<string,string>={}
      const fileMap:Record<string,File>={}
      files.forEach((f,i)=>{
        attachments[hashes[i]]=f.name
        fileMap[hashes[i]]=f
      })
      const {uploadUrls,id}=await this.api
          .put<Api.putExpense.Res>(`admin/expense/${user}`, {
            ...exp,
            attachments: attachments
          } as Api.putExpense.Req)
          .toPromise()

      return this.util.uploadFiles(Object.entries(fileMap).map(([hash,f])=>[uploadUrls[hash],f]))
        .pipe(map(p=>({id,progress:p})))
    }).pipe(switchMap(v=>v))
  }
  updateExpense({user,id,...exp}:Replace<Api.patchExpense.Req,{newAttachments:File[],id:string,user:string}>):Observable<{progress:number}>{
    return defer(async () => {
      const files=exp.newAttachments
      const hashes=await this.util.hashFilesBackground(files as any)
      const attachments:Record<string,string>={}
      const fileMap:Record<string,File>={}
      files.forEach((f,i)=>{
        attachments[hashes[i]]=f.name
        fileMap[hashes[i]]=f
      })
      const {uploadUrls}=await this.api.patch<Api.patchExpense.Res>(`admin/expense/${user}/${id}`,{
        ...exp,
        newAttachments:attachments
      } as Api.patchExpense.Req).toPromise()
      return this.util.uploadFiles(Object.entries(fileMap).map(([hash,f])=>[uploadUrls[hash],f]))
        .pipe(map(p=>({progress:p})))
    }).pipe(switchMap(v=>v))
  }
  getExpense(user: string, id: string) {
    return this.api.get<Api.getExpense.Res>(`admin/expense/${user}/${id}`)
      .pipe(switchMap(async v=>({
        ...v,
        user:await this.us.getUser(v.user)
      })));
  }
  deleteExpenses(req:Api.deleteExpensesAdmin.Req){
    return this.api.request("delete","admin/expense",{
        body:req
    })
  }
  markAsPayed(req:Api.markPayedAdmin.Req){
    return this.api.post<void>("admin/markPayed",req)
  }

  createExpenseType(type:Api.putExpenseTypeAdmin.Req){
    return this.api.put<Api.putExpenseTypeAdmin.Req>("admin/expense-type",type)
  }
  editExpenseType({id,...req}:Api.patchExpenseTypeAdmin.Req&{id:string}){
    return this.api.patch(`admin/expense-type/${id}`,req)
  }
  deprecateExpenseTypes(...ids:string[]){
    const req:Api.massDeprecateExpenseTypeAdmin.Req={}
    ids.forEach(id=>{
      req[id]=true
    })
    return this.api.post<void>("admin/expense-type/deprecate",req)
  }
  async setMetadata<T extends keyof Metadata>(key:T,value:Metadata[T]){
    await this.api.post("admin/metadata",{
      key,
      value
    } as Api.setMetadata.Req).toPromise()
  }
}
