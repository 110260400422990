<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <p>{{data.text}}</p>
</div>
<div mat-dialog-actions>
    <ng-container *ngFor="let button of data.buttons" [ngSwitch]="button.type">
        <button mat-raised-button [color]="button.color" *ngSwitchCase="'raised'" [disabled]="waiting" (click)="call(button)">
            {{button.name}}
            <mat-spinner *ngIf="waits[button.name]" color="accent" class="start-spinner" diameter="25" class="button-spinner"></mat-spinner>
        </button>
        <button mat-button *ngSwitchDefault [color]="button.color" [disabled]="waiting" (click)="call(button)">
            {{button.name}}
            <mat-spinner *ngIf="waits[button.name]" color="accent" class="start-spinner" diameter="25" class="button-spinner"></mat-spinner>
        </button>
    </ng-container>
</div>
