import { DatePipe, Location } from '@angular/common';
import { ApplicationRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatRadioGroup } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdminService } from '../services/admin.service';
import { ExpenseService } from '../services/expense.service';
import { Api, Replace } from '../../backendTypes';
import { Attachment, ExpenseType, User } from '../types';
import { share } from 'rxjs/operators';
import { NEVER, Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { UserService } from '../services/user.service';
import { createForm, getTaxAmount } from '../util';
import { Moment } from 'moment';
import * as moment from 'moment';
@Component({
  selector: 'app-spesen-form',
  templateUrl: './spesen-form.component.html',
  styleUrls: ['./spesen-form.component.scss'],
})
@UntilDestroy()
export class SpesenFormComponent implements OnInit {
  constructor(
    private api: ExpenseService,
    private admin: AdminService,
    private us: UserService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private appRef: ApplicationRef
  ) {}
  users:User[]
  expensetypes: ExpenseType[] = [];
  allExpenseTypes: ExpenseType[] = [];
  oldExpenseType?: ExpenseType;
  minDate?:Moment
  get vatAmount(){
    return this.expenseForm.value.sums?.reduce((acc,s)=>acc+getTaxAmount(s.vat,s.amount),0)
  }
  get amount(){
    return this.expenseForm.value.sums?.reduce((acc,s)=>acc+s.amount,0)
  }
  get sumControls(){
    return this.expenseForm.controls.sums.controls
  }
  get isDetailMode(): boolean {
    return this.isDetails;
  }
  get isEditable() {
    console.log("EDITAble",this.expenseForm.enabled)
    return this.expenseForm.enabled
  }

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('taxRadioButtons') taxRadioButtons: MatRadioGroup;
  fileAttr = 'Datei Hochladen';

  currentExpense?:Replace<Api.getExpense.Res, {user:User}>
  expenseForm=createForm({
      user: new FormControl(''),
      typeId: new FormControl('', Validators.required),
      date: new FormControl('', [Validators.required]),
      description: new FormControl('', Validators.required),
      payed: new FormControl(false),
      passOn: new FormControl(false),
      attachments: new FormControl([]),
      project: new FormControl(''),
      customer: new FormControl(''),
      sums:createForm([
        createForm({
          amount:new FormControl('',Validators.required),
          vat:new FormControl('',Validators.required)
        })
      ])
  })
  selectedFiles: File[] = [];
  radioButtonsTax = [2.6, 3.8, 8.1];
  isDetails = false;
  isAdmin=false;
  currentId: string;

  // For expense edit only
  removeAttachment: string[] = [];
  /**
   * if undefined not buffering
   * if unresolved buffering
   * if {progress:number} showing progress
   */
  progress?: Observable<{ progress: number }>;
  attachmentError?: string;

  ngOnInit(): void {
    this.getExpenseTypes();
    this.us.loadList().then(users=>this.users=[...users.values()].sort((a,b)=>a.displayName.localeCompare(b.displayName)))
    //not subscribing to a more specific controls, because those could be replaced
    this.expenseForm.controls.sums.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe({next:()=>this.toggleAttachmentValidator()})
    this.expenseForm.controls.payed.disable();
    this.us.getMetadata().then(v=>this.minDate=moment(v.cutoffDate).add(1,"d"))

    this.getDetails();
  }

  getExpenseTypes() {
    this.api.listExpenseTypes().subscribe((expenseTypes) => {
      this.expensetypes = expenseTypes.filter((v) => !v.deprecated);
      this.allExpenseTypes = expenseTypes;
    });
  }

  toggleAttachmentValidator() {
    //get value from sums control directly, because thats where the event comes
    if(this.expenseForm.controls.sums.length==1 && this.expenseForm.controls.sums.value[0].vat=="no receipt"){
      this.expenseForm.controls.attachments.disable()
    } else {
      this.expenseForm.controls.attachments.enable()
    }
    this.validateAttachments();
  }

  uploadFileEvt(file: any): void {
    if (file?.target?.files.length) {
      const files: File[] = Array.from(file.target.files);
      this.fileAttr = files.map((file) => file.name).join(' - ');

      this.selectedFiles = files;

      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = '';
    } else {
      this.fileAttr = 'Dateien Auswählen';
    }
    this.validateAttachments();
  }

  async createNewExpense() {
    if (!this.validateAttachments() || !this.expenseForm.valid || this.progress) {
      console.log(this.expenseForm);
      this.expenseForm.markAllAsTouched();
      return;
    }
    let {user, ...newExpense }:Omit<Api.putExpense.Req, 'attachments'>&{user:string} ={
      ...this.expenseForm.value,
      date:this.datePipe.transform(this.expenseForm.value.date, 'yyyy-MM-dd') ?? ""
    }
    if(this.isAdmin){
      this.progress = this.admin.createExpense({ ...newExpense,user }, this.selectedFiles)
        .pipe(share())
    }else{
      this.progress = this.api.createExpense(newExpense, this.selectedFiles)
        .pipe(share())
    }
    this.progress.subscribe((n) => console.log('PROG', n.progress));
    await this.progress.toPromise()
    this.close()
  }

  async patchExpense() {
    if (!this.validateAttachments() || !this.expenseForm.valid || this.progress) {
      this.expenseForm.markAllAsTouched();
      return;
    }
    const value={
      ...this.expenseForm.value,
      date:this.datePipe.transform(this.expenseForm.value.date, 'yyyy-MM-dd') ?? "",

      id:this.currentId,
      newAttachments:this.selectedFiles,
      removeAttachments:this.removeAttachment,
    }
    if(this.isAdmin){
      this.progress = this.admin.updateExpense(value)
        .pipe(share());
    }else{
      this.progress = this.api.updateExpense(value)
        .pipe(share());
    }
    this.progress.subscribe((n) => console.log('PROG', n.progress));
    await this.progress.toPromise()
    this.close()
  }

  isProceedExpenses(): boolean {
    const passOnString = 'passOn';
    return this.expenseForm.controls[passOnString].value;
  }

  lockOnPayed() {
    if (this.expenseForm.controls['payed'].value) {
      this.expenseForm.disable();
    }
  }

  // Checks if new Expense or existing
  getDetails() {
    this.route.params.subscribe({
      next: async ({ id, user }) => {
        this.isAdmin=!!this.route.snapshot.data.admin;
        if (id) {
          this.isDetails = true;
          this.currentId = id;
          const result =await (this.isAdmin ? this.admin.getExpense(user, id).toPromise() : this.api.getExpense(id).toPromise())
          this.oldExpenseType = this.allExpenseTypes.find((v) => v.id == result.typeId && v.deprecated);
          this.currentExpense=result
          this.expenseForm.controls.sums.clear()
          result.sums.forEach(s=>this.addVat())
          this.expenseForm.setValue({
            user: result.user.id,
            typeId: result.typeId,
            date: result.date,
            description: result.description,
            payed: result.payed,
            passOn: result.passOn,
            attachments: result.attachments,
            project: result.project,
            customer: result.customer,
            sums:result.sums
          });
          this.fileAttr = result.attachments.map((v) => v.name).join(' - ');
          this.validateAttachments();
          this.lockOnPayed();
          this.appRef.tick()
        } else {
          this.isDetails = false;
        }
      },
    });
  }
  validateAttachments(): boolean {
    const attachments=this.expenseForm.value.attachments
    if(attachments && attachments.length + this.selectedFiles == 0) {
      this.attachmentError = 'Mindestens ein Beleg muss hochgeladen werden';
      return false;
    }
    this.attachmentError = undefined;
    return true;
  }
  downloadAttachment(attachment: Attachment): void {
    window.location.href = attachment.url;
  }
  async deleteAttachment(attachment: Attachment) {
    if(this.progress) return;

    const val={
      ...this.currentExpense!,
      removeAttachments:[attachment.hash],

      newAttachments:[],
      attachments:undefined,
      payed:undefined,
      user:undefined
    }
    this.progress=NEVER
    await this.api.updateExpense(val).toPromise()
    const index = this.expenseForm.controls['attachments'].value
        .findIndex((a:Attachment)=>a.hash==attachment.hash)
    if (index > -1) {
      this.expenseForm.controls['attachments'].value.splice(index, 1);
    }
    this.validateAttachments();
    this.progress=undefined
  }

  async deleteExpense(id: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '250px',
    });
    const result=await dialogRef.afterClosed().toPromise()
    if(result){
      this.progress=of(undefined) as any
      if (this.isAdmin) {
        // User ID
        const user = this.expenseForm.controls['user'].value;
        await this.admin.deleteExpenses({ [user]: [id] }).toPromise();
      } else {
        await this.api.deleteExpenses([id]).toPromise();
      }
      this.close()
    }
  }

  clearFiles(){
    this.fileAttr='Datei Hochladen';
    this.selectedFiles.length=0;
    this.validateAttachments();
  }


  isImage(fileName: string): boolean {
    const fileFormats = ['png', 'jpg', 'jpeg'];
    return fileFormats.includes(fileName.split('.')[1]);
  }
  close(){
    this.location.back();
      this.progress = undefined;
  }
  addVat(){
    this.expenseForm.controls.sums.push(createForm({
      amount:new FormControl('',Validators.required),
      vat:new FormControl('',Validators.required)
    }))
  }
  removeVat(i:number){
    this.expenseForm.controls.sums.removeAt(i)
  }
}
