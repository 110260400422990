import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { createForm } from '../util';

@Component({
  selector: 'app-new-expensetype',
  templateUrl: './new-expensetype.component.html',
  styleUrls: ['./new-expensetype.component.scss'],
})
export class NewExpensetypeComponent implements OnInit {
  constructor(
    private adminS: AdminService,
    private router: Router
  ) {}

  expenseTypeForm= createForm({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    defaultVat: new FormControl(0, Validators.required),
    accountId: new FormControl(''),
  })
  saving=false

  ngOnInit(): void {}

  saveExpenseType(): void {
    if (!this.expenseTypeForm.valid || this.saving) {
      this.expenseTypeForm.markAllAsTouched();
      return;
    }
    this.saving=true
    this.adminS.createExpenseType(this.expenseTypeForm.value).subscribe(() => {
      this.router.navigate(['/expense-type-overview']);
      this.saving=false
    });
  }
}
