<mat-toolbar>
  <span>Übersicht: Meine Spesen</span>
  <span class="actionbar-spacer"></span>
  <button mat-raised-button [disabled]="!isData() || this.exportPDFthrobber" (click)="exportPDF()"><mat-spinner *ngIf="exportPDFthrobber" diameter="25"></mat-spinner>Export to PDF</button>
  <button mat-raised-button class="export-button" routerLink="/new">Neue Spesen erfassen</button>
</mat-toolbar>

<mat-card>
  <mat-form-field class="filter-field">
    <mat-label>Spesendatum</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="dateFilter" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="multi-year" (monthSelected)="closeDatePicker($event, picker)"></mat-datepicker>
  </mat-form-field>
  <button mat-icon-button class="filterCloseBtn" *ngIf="dateFilter" (click)="resetDateFilter()"><mat-icon>close</mat-icon></button>
  <mat-form-field class="filter-field">
    <mat-label>Filter Spesenart</mat-label>
    <mat-select [(ngModel)]="typeIdFilter" (ngModelChange)="filterData()">
      <mat-option>-- kein Filter --</mat-option>
      <mat-option *ngFor="let avalilableExpensType of avalilableExpensTypes" [value]="avalilableExpensType.id">{{
        avalilableExpensType.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="filter-field">
    <mat-label>Filter MwSt.</mat-label>
    <mat-select [(ngModel)]="vatFilter" (ngModelChange)="filterData()">
      <mat-option>-- kein Filter --</mat-option>
      <mat-option *ngFor="let vat of vatTypes" [value]="vat">{{ vat }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="openExpensesFilter" (ngModelChange)="filterData()">Nur offene Spesen</mat-checkbox>
  <mat-menu #attachMenu="matMenu">
    <ng-template matMenuContent let-attachm="attachments" let-exp="exp">
      <buttom mat-menu-item *ngFor="let a of attachm | keyvalue" (click)="getAttachment(exp,$any(a.key))">{{a.value}}</buttom>
    </ng-template>
  </mat-menu>
  <table
    matSort
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 overview-table"
    (matSortChange)="sortData($event)"
    aria-describedby="Overview Table"
  >
    <ng-container matColumnDef="date">
      <th id="date" mat-header-cell mat-sort-header="date" *matHeaderCellDef>Datum</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | date: 'dd.MM.yyyy' }}
      </td>
      <td mat-footer-cell *matFooterCellDef>Total</td>
    </ng-container>

    <ng-container matColumnDef="typeId">
      <th id="typeId" mat-header-cell mat-sort-header="typeId" *matHeaderCellDef>Spesenart</th>
      <td mat-cell *matCellDef="let element">{{ expenseTypes.get(element.typeId)?.name + ( expenseTypes.get(element.typeId)?.deprecated ? " [OLD]" :"" )}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th id="amount" mat-header-cell mat-sort-header="amount" *matHeaderCellDef>Betrag</th>
      <td mat-cell *matCellDef="let element">{{ element.amount | currency: 'CHF' }}</td>
      <td mat-footer-cell *matFooterCellDef>{{total | currency: 'CHF'}}</td>
    </ng-container>

    <ng-container matColumnDef="vat">
      <th id="vat" mat-header-cell mat-sort-header="vat" *matHeaderCellDef>MwSt-Satz</th>
      <td mat-cell *matCellDef="let element">{{element.vat=="no receipt" ? "Keine Quittung" :element.vat=="MULTIPLE" ? "Mehrere" :element.vat+"%" }}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vatAmount">
      <th id="taxAmount" mat-header-cell mat-sort-header="vatAmount" *matHeaderCellDef>MwSt-Betrag</th>
      <td mat-cell *matCellDef="let element">{{ element.vatAmount | currency: 'CHF' }}</td>
      <td mat-footer-cell *matFooterCellDef>{{ totalVat | currency:'CHF'}}</td>
    </ng-container>

    <ng-container matColumnDef="payed">
      <th id="payed" mat-header-cell mat-sort-header="payed" *matHeaderCellDef>Bezahlt</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.payed">check_box</mat-icon>
        <mat-icon *ngIf="!element.payed">check_box_outline_blank</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="attachments">
      <th id="attachments" mat-header-cell mat-sort-header="attachments" *matHeaderCellDef>Anhang</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button *ngIf="isAttachment(element)"
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="attachMenu" [matMenuTriggerData]="{exp:element,attachments:element.attachments}"><mat-icon class="attachment-icon">attachment</mat-icon></button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="overview-row"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [routerLink]="['/details', row.id]"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</mat-card>
