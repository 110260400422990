import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService, ApplicationStatus } from "./authentication.service";
import { first } from "rxjs/operators"
interface AuthGuardData{
    /** user must have one of these Roles */
    roles:string|string[]
}
@Injectable({
    providedIn:"root"
})
export class AuthGuard implements CanActivate{
    constructor(
        private auth:AuthenticationService
    ){}
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const status=await this.auth.applicationStatus$.pipe(first()).toPromise()
        if(status==ApplicationStatus.AUTHENTICATED){
            const roles:AuthGuardData["roles"]=route.data.auth?.roles
            const requiredRoles=typeof roles=="string"
                ? [roles]
                : roles
            if(roles){
              return Promise.all(
                requiredRoles.map(r=>this.auth.hasRole(r))
              ).then(v=>v.some(Boolean))
            }
            return true
        }else{
            await this.auth.openFederatedLogin()
            return false
        }
    }

}
