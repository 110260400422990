import { Injectable } from "@angular/core";
import { Api, User} from "../../backendTypes"
import { ApiGatewayClient } from "../httpClients/apiGateway.client";

@Injectable({
  providedIn:"root"
})
export class UserService{
  private userList:Promise<Map<string,User>>
  constructor(
    private api:ApiGatewayClient
  ){}
  async getUser(id:string):Promise<User>{
    const userList=await (this.userList || this.loadList())
    const user=userList.get(id)
    if(user) return user
    const placeholder={
      id,
      displayName:id,
      mail:"unknown"
    }
    userList.set(id,placeholder)
    return placeholder
  }
  async getMetadata(){
    return this.api.get<Api.getMetadata.Res>("/metadata").toPromise()
  }
  loadList(){
    return this.userList=this.api.get<Api.listUsers.Res>("user").toPromise()
      .then(users=>new Map(users.map(v=>[v.id,v])))
  }
}
