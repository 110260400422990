import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { Api, Replace } from '../../backendTypes';
import { ApiGatewayClient } from '../httpClients/apiGateway.client';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService {
  constructor(
    private api: ApiGatewayClient,
    private us: UserService,
    private util: UtilService
  ) {}
  listExpenses() {
    return this.api.get<Api.listExpenses.Res>('expense')
      .pipe(switchMap(async w=>Promise.all(w.map(async v=>({
        ...v,
        user:await this.us.getUser(v.user)
      })))))
  }
  getExpense(id: string) {
    return this.api.get<Api.getExpense.Res>(`expense/${id}`)
      .pipe(switchMap(async v=>({
        ...v,
        user:await this.us.getUser(v.user)
      })));
  }
  /**
   *@return id of new Expense
   */
  createExpense(exp: Omit<Api.putExpense.Req, 'attachments'>, files: File[]):Observable<{progress:number,id:string}> {
    return defer(async () => {
      const hashes=await this.util.hashFilesBackground(files as any)
      const attachments:Record<string,string>={}
      const fileMap:Record<string,File>={}
      files.forEach((f,i)=>{
        attachments[hashes[i]]=f.name
        fileMap[hashes[i]]=f
      })
      const {uploadUrls,id}=await this.api
          .put<Api.putExpense.Res>('expense', {
            ...exp,
            attachments: attachments
          } as Api.putExpense.Req)
          .toPromise()

      return this.util.uploadFiles(Object.entries(fileMap).map(([hash,f])=>[uploadUrls[hash],f]))
        .pipe(map(p=>({id,progress:p})))
    }).pipe(switchMap(v=>v))
  }
  updateExpense({id,...exp}:Replace<Api.patchExpense.Req,{newAttachments:File[],id:string}>):Observable<{progress:number}>{
    return defer(async () => {
      const files=exp.newAttachments
      const hashes=await this.util.hashFilesBackground(files as any)
      const attachments:Record<string,string>={}
      const fileMap:Record<string,File>={}
      files.forEach((f,i)=>{
        attachments[hashes[i]]=f.name
        fileMap[hashes[i]]=f
      })
      const {uploadUrls}=await this.api.patch<Api.patchExpense.Res>(`expense/${id}`,{
        ...exp,
        newAttachments:attachments
      } as Api.patchExpense.Req).toPromise()
      return this.util.uploadFiles(Object.entries(fileMap).map(([hash,f])=>[uploadUrls[hash],f]))
        .pipe(map(p=>({progress:p})))
    }).pipe(switchMap(v=>v))
  }
  deleteExpenses(req:Api.deleteExpenses.Req){
    return this.api.request("delete","expense",{
        body:req
    })
  }
  listExpenseTypes() {
    return this.api.get<Api.listExpenseType.Res>('expense-type');
  }
}
