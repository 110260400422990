import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../services/admin.service';

@Component({
    selector: 'app-dialog-close-month',
    templateUrl: './dialog-close-month.component.html',
    styleUrls: ['./dialog-close-month.component.scss']
})
export class DialogCloseMonthComponent implements OnInit {
    dateControl=new FormControl(null,Validators.required)
    waiting=false
    constructor(
        private ref: MatDialogRef<any>,
        private as: AdminService
    ) { }
    ngOnInit(): void {
    }
    async save(){
      if(!this.dateControl.valid) return;
      this.waiting=true
      this.ref.disableClose=true
      console.log(this.dateControl.value)
      await this.as.setMetadata("cutoffDate",this.dateControl.value.format('YYYY-MM-DD'))

      this.waiting=false
      this.ref.disableClose=false
      this.ref.close()
    }
    cancel(){
      this.ref.close()
    }
}
