<mat-sidenav-container class="mat-typography" fullscreen>
  <mat-sidenav #sidenav mode="side" [(opened)]="opened">
    <header class="sideHeader">
      <button mat-button color="accent" (click)="toggleSidenav(false)" style="float: right">X</button>
      <div class="wgc-slidebar-title">
        <div class="wgc-slidebar-t">
          <h3>Spesenapp</h3>
        </div>
      </div>
    </header>
    <mat-expansion-panel>
      <mat-expansion-panel-header color="primary"> Meine Ansichten </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item routerLink="/overview">Übersicht</a>
        <a mat-list-item routerLink="/new">Neue Spesen</a>
      </mat-nav-list>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="hrUser">
      <mat-expansion-panel-header color="primary">Backoffice</mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item routerLink="/expense-type-overview">Spesentypen</a>
        <a mat-list-item routerLink="/backoffice">Alle Spesen</a>
        <a mat-list-item routerLink="/backoffice" [queryParams]="{filOpen:''}">Alle Offenen Spesen</a>
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header (sidenavToggle)="toggleSidenav(true)"></app-header>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
