import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSort, Sort } from '@angular/material/sort';
import { Moment } from 'moment';
import * as PDFtemplate from '../PDFtemplate';
import { Api, User, ExpenseWDownloads, Replace} from '../../backendTypes';
import { AdminService } from '../services/admin.service';
import { ExpenseService } from '../services/expense.service';
import { Expense, ExpenseType, ListExpense } from '../types';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { getTaxAmount } from '../util';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from "moment"
import { MatDialog } from '@angular/material/dialog';
import { DialogCloseMonthComponent } from '../dialog-close-month/dialog-close-month.component';

export const MY_FORMATS = {
  display: {
    dateInput: 'MM.YYYY',
  },
};

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BackofficeComponent implements OnInit {
  @ViewChild(MatSort) matSort: MatSort;

  constructor(
    private datePipe: DatePipe,
    private expS: ExpenseService,
    private adminS: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}
  get allPayed():"all"|"some"|"none"{
    const payed=this.dataSource.filter(v=>v.payed).length
    const total=this.dataSource.length
    if(payed==total) return "all"
    else if(payed==0) return "none"
    else return "some"
  }
  get total(){
    return this.dataSource
      .map(v=>v.amount)
      .reduce((o,c)=>o+c,0)
  }
  get totalMwst(){
    return this.dataSource
      .map(v=>v.vatAmount)
      .reduce((o,c)=>o+c,0)
  }
  displayedColumns: string[] = ['date', 'typeId', 'amount', 'vat', 'vatAmount', 'payed', 'user', 'attachments'];
  sortedData: any[];
  unfilteredDataSource: ListExpense[];
  expenseTypes = new Map<string, ExpenseType>();

  dataSource: ListExpense[] = [];

  typeIdFilter?: string;
  dateFilter?: Moment;
  userFilter?: string;
  openExpensesFilter: boolean;

  exportPDFthrobber=false

  availableUsers: User[];
  availableUsersFiltered:User[];
  avalilableExpensTypes: ExpenseType[];
  loading = true;
  getTaxAmount=getTaxAmount
  ngOnInit(): void {
    this.adminS.listExpenses().subscribe(async (expenses) => {
      this.loadFilterSort(this.route.snapshot.queryParamMap)
      this.route.queryParamMap.subscribe(v=>{
          if(!this.updatingQueryParams){
              this.loadFilterSort(v)
              this.filterData()
          }
      })
      this.dataSource = expenses;
      this.unfilteredDataSource = this.dataSource;
      this.availableUsers = this.getAvailableUsers();
      this.availableUsersFiltered=this.availableUsers

      this.avalilableExpensTypes = await this.getAvailableExpenseTypes();
      this.filterData()
      this.sortData(this.matSort)
      this.loading = false;
    });
    this.expS
      .listExpenseTypes()
      .toPromise()
      .then((types) => {
        types.forEach((v) => this.expenseTypes.set(v.id, v));
      });
  }
  loadFilterSort(map:ParamMap){
    this.typeIdFilter=map.get("filType") ?? undefined
    this.dateFilter=map.has("filDate") ? moment(map.get("filDate")) : undefined
    this.userFilter=map.get("filUser") ?? undefined
    this.openExpensesFilter=map.has("filOpen")
    this.matSort.sort({id:map.get("sortId") ?? "date",start:map.get("sortDir") ?? "asc" as any, disableClear:false})
  }
  sortData(sort: Sort): any {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const key:keyof ListExpense=sort.active as any
    data.sort((a, b) => {
      const flip=sort.direction=="asc" ? -1 : 1
      switch (key) {
        case 'user':
          return a.user.displayName.localeCompare(b.user.displayName)*flip
        case 'attachments':
          return (Object.keys(a.attachments).length - Object.keys(b.attachments).length)*flip
        default:
          if(typeof a[key]=="number" || typeof a[key]=="boolean"){
            return (<number>a[key]-<number>b[key])*flip
          }else if(typeof a[key]=="string"){
            return (a[key] as string).localeCompare(b[key] as string)*flip
          }else{
            return 0
          }
      }
    });
    this.dataSource = data;
    const isDefault=sort.active=="date" && sort.direction=="asc"
    this.updateQueryParams()
  }

  closeDatePicker(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.dateFilter = normalizedMonth;
    datepicker.close();
    this.filterData();
  }

  filterData(): void {
    this.dataSource = this.unfilteredDataSource;

    this.dataSource = this.dataSource
      .filter((element) => !this.typeIdFilter || element.typeId == this.typeIdFilter)
      .filter(
        (element) =>
          !this.dateFilter ||
          this.datePipe.transform(element.date, 'yyyy-MM') ==
            this.datePipe.transform(this.dateFilter.toDate(), 'yyyy-MM')
      )
      .filter((element) => !this.openExpensesFilter || element.payed != this.openExpensesFilter);
    this.availableUsersFiltered=this.availableUsers
      .filter(u=>this.dataSource.some(e=>e.user==u))
    this.dataSource=this.dataSource
          .filter((element) => !this.userFilter || element.user.id == this.userFilter)
    this.sortData(this.matSort)
  }
  updatingQueryParams=false
  async updateQueryParams(){
    const sortDefault=this.matSort.active=="date" && this.matSort.direction=="asc"
    this.updatingQueryParams=true
    await this.router.navigate([],{
      replaceUrl:true,
      queryParams:{
        filDate:this.dateFilter ? this.datePipe.transform(this.dateFilter.toDate(), 'yyyy-MM') :undefined,
        filType:this.typeIdFilter,
        filUser:this.userFilter,
        filOpen:this.openExpensesFilter ? "" : undefined,
        sortId:!sortDefault ? this.matSort.active : undefined,
        sortDir:!sortDefault ? this.matSort.direction : undefined
      }
    })
    this.updatingQueryParams=false
  }
  resetDateFilter() {
    this.dateFilter = undefined;
    this.filterData();
  }

  getAvailableUsers(): User[] {
    return [...new Set(this.unfilteredDataSource.map(v=>v.user))]
      .sort((a,b)=>a.displayName.localeCompare(b.displayName))
  }
  async getAvailableExpenseTypes(){
    const types=await this.expS.listExpenseTypes().toPromise()
    return types.filter(v=>this.unfilteredDataSource.some(exp=>exp.typeId==v.id))
  }
  isData(): boolean {
    return this.dataSource.length > 0;
  }

  isAttachment(element: Expense): boolean {
    return Object.keys(element.attachments).length > 0 || Number(element.attachments.length) > 0;
  }
  async exportPDF() {
    this.exportPDFthrobber=true
    const list:ListExpense[]=await Promise.all(this.dataSource.map(async v=>{
      if(v.vat=="MULTIPLE"){
          const item=await this.adminS.getExpense(v.user.id,v.id).toPromise()
          return item.sums.map<ListExpense>(v=>({
            ...item,
            amount:v.amount,
            vat:v.vat!,
            vatAmount:typeof v.vat=="number" ? v.vat * v.amount : 0,
            attachments:item.attachments.length ? {PLACE:"HOLDER"} : {} as {}
          }))
      }else{
          return [v]
      }
    })).then(v=>v.reduce((o,c)=>o.concat(c),[]))

    PDFtemplate.exportExpensesAllEmployees(list,{
      typeId:this.typeIdFilter,
      date:this.dateFilter,
      user:this.availableUsers.find(v=>v.id==this.userFilter),
      openExpenses:this.openExpensesFilter
    });
    this.exportPDFthrobber=false
  }
  togglePayedReq: Api.markPayedAdmin.Req = {};
  togglePayedP?: Promise<void>;
  togglePayed(row: ListExpense,newState:boolean) {
    if (!this.togglePayedReq[row.user.id]) this.togglePayedReq[row.user.id] = {};
    this.togglePayedReq[row.user.id][row.id] = newState;
    row.payed=newState
    //batches requests together
    const timeout = 2_000; //ms
    if (!this.togglePayedP) {
      this.togglePayedP = new Promise((res) => setTimeout(res, timeout))
        .then(() => {
          console.log(this.togglePayedReq);
          const fin = this.adminS.markAsPayed(this.togglePayedReq);
          this.togglePayedReq = {};
          return fin.toPromise();
        })
        .then(() => (this.togglePayedP = undefined));
    }
  }

  togglePayedAll(event: MatCheckboxChange) {
    console.log("PAY",event)
    this.dataSource.forEach((element) => {
      if(event.checked!=element.payed){
        this.togglePayed(element,event.checked)
      }
    });
  }
  attachmentCache=new Map<string,Replace<ExpenseWDownloads,{user:User}>>()
  async getAttachment({user,id}:Expense,attachm:string){
    const key=`${user}:${id}`
    let exp=this.attachmentCache.get(key)
    if(!exp){
      exp=await this.adminS.getExpense(user.id,id).toPromise()
      this.attachmentCache.set(key,exp)
      if(this.attachmentCache.size>10){
        this.attachmentCache.delete(this.attachmentCache.keys().next().value)
      }
    }
    const url=exp.attachments.find((v:any)=>v.hash==attachm)!.url
    window.location.href = url;
  }
  setCutoff(){
    return this.dialog.open(DialogCloseMonthComponent).afterClosed().toPromise()
  }
}
