export const environment:typeof import("./environment.dev").environment = {
    production: true,
    api: 'spesenapp-api-dev.wgcdev.ch/spesenapp',
    region:'eu-central-1',
    auth: {
      clientId:'3pec1hodd51uaf66oaao2segpe',
      userPoolId:'eu-central-1_KTry7TNxL',
      allowRegister:false,
      identityProviderSettings:{
          domainName:'wgc-spesenappdev.auth.eu-central-1.amazoncognito.com',
          redirectUri:'https://spesenapp-dev.wgcdev.ch',
          providerName:'spesenappdevelopAAD',
          tokenMode:false
      }
    },
};