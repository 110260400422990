import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  authStat = this.auth.applicationStatus$;
  userName = this.auth.user$.pipe(map((v) => v?.email));
  constructor(private auth: AuthenticationService) {}

  @Output() sidenavToggle = new EventEmitter<boolean>();

  toggleSidenav(opened: boolean): void {
    this.sidenavToggle.emit(opened);
  }
  logout() {
    this.auth.logout();
  }
  login() {
    this.auth.openFederatedLogin();
  }
}
