import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from './authentication/authentication.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'spesenapp';
  opened = true;
  hrUser?:boolean
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  constructor(
      private auth:AuthenticationService
  ){}
  ngOnInit(){
    this.auth.handleLastRoute()
    this.auth.hasRole("HR").then(v=>this.hrUser=v)
  }
  public toggleSidenav(action: boolean): void {
    this.opened = action;
  }
}
