import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogGeneralComponent, DialogGeneralData } from '../dialog-general/dialog-general.component';
import { AdminService } from '../services/admin.service';
import { ExpenseService } from '../services/expense.service';
import { ExpenseType } from '../types';
import { createForm } from '../util';

@Component({
  selector: 'app-expensetype-overview',
  templateUrl: './expensetype-overview.component.html',
  styleUrls: ['./expensetype-overview.component.scss'],
})
export class ExpensetypeOverviewComponent implements OnInit {
  displayedColumns: string[] = ['select','name', 'taxType', 'description', 'bexioCustomerNumber', "menu"];
  dataSource: ExpenseType[] = [];

  selection=new SelectionModel<ExpenseType>(true)
  focusWhenReady?:string
  loading=true
  @ViewChild('nameField') set nameField(f:ElementRef){this.selectEdit(f,"name")}
  @ViewChild('descriptionField') set descriptionField(f:ElementRef){this.selectEdit(f,"description")}
  @ViewChild(MatSort) matSort:MatSort;
  get allSelected(){
    return this.dataSource.length==this.selection.selected.length
  }


  constructor(
    private adapter: DateAdapter<any>,
    private api: ExpenseService,
    private adminS: AdminService,
    private dialog: MatDialog,
    private cdRef:ChangeDetectorRef,
    private router:Router,
    private route:ActivatedRoute
  ) {
    this.adapter.setLocale('de');
  }

  ngOnInit(): void {
    this.api.listExpenseTypes().subscribe((expenseTypes) => {
      this.dataSource = expenseTypes.filter(v=>!v.deprecated);
      this.loading=false
      this.loadSort();
    });
  }
  loadSort(){
    const map=this.route.snapshot.queryParamMap
    this.matSort.sort({id:map.get("sortId") ?? "name",start:map.get("sortDir") as any ?? "asc",disableClear:false})
  }
  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') return;

    data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'bexioCustomerNumber':
          return compare(a.accountId, b.accountId, isAsc);
        case 'taxType':
          return compare(a.defaultVat, b.defaultVat, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = data;
    const isDefault=sort.active=="name" && sort.direction=="asc"
    this.router.navigate([],{
      replaceUrl:true,
      queryParamsHandling:"merge",
      queryParams:{
        sortId:!isDefault ? sort.active : undefined,
        sortDir:!isDefault ? sort.direction : undefined
      }
    })
  }
  toggleAll(){
    if(this.allSelected){
      this.selection.clear()
    }else{
      this.selection.select(...this.dataSource)
    }
  }
  async delete(row?:ExpenseType){
    console.log(row)
    if(row){
      await this.dialog.open<DialogGeneralComponent,DialogGeneralData>(DialogGeneralComponent,{
        data:{
          title:"Are you Sure?",
          text:`are you sure you want to delete ${row.name}?`,
          buttons:[
              {name:"Yes",color:"primary",type:"raised",action:async ref=>{
                  await this.adminS.deprecateExpenseTypes(row.id).toPromise()
                  this.selection.deselect(row)
                  this.dataSource=this.dataSource.filter(v=>v!=row)
                  ref.close()
              }},
              {name:"Cancel",action(ref){ref.close()}}
          ]
        },
      }).afterClosed().toPromise()
    }else{
      const selection=this.selection.selected
      await this.dialog.open<DialogGeneralComponent,DialogGeneralData>(DialogGeneralComponent,{
        data:{
          title:"Are you Sure?",
          text:`are you sure you want to delete ${selection.length} Types?`,
          buttons:[
            {name:"Yes",color:"primary",type:"raised",action:async ref=>{
              await this.adminS.deprecateExpenseTypes(...selection.map(v=>v.id)).toPromise()
              this.selection.clear()
              this.dataSource=this.dataSource.filter(v=>!selection.includes(v))
              ref.close()
            }},
            {name:"Cancel",action(ref){ref.close()}}
          ]
        },
      }).afterClosed().toPromise()
    }
  }

  currentlyEditing?:ExpenseType
  saving=new SelectionModel<ExpenseType>(true)
  editForm=createForm({
    name:new FormControl(null,Validators.required),
    description:new FormControl(null),
  })
  async edit(row:ExpenseType,focus?:string){
    if(this.currentlyEditing==row) return;
    if(this.saving.isSelected(row)) return;
    if(this.currentlyEditing && this.editForm.dirty){
      let editNew=false
      await this.dialog.open<DialogGeneralComponent,DialogGeneralData>(DialogGeneralComponent,{
        data:{
          title:"Save changes?",
          text:`you forgot to save the changes`,
          buttons:[
            {name:"Save",color:"primary",type:"raised",action:async ref=>{
              await this.editSave()
              editNew=true
              ref.close()
            }},
            {name:"Dont Save", color:"accent",action:ref=>{
              editNew=true
              ref.close()
            }},
            {name:"Cancel",action(ref){ref.close()}}
          ]
        },
      }).afterClosed().toPromise()
      if(!editNew) return;
    }
    this.currentlyEditing=row
    this.focusWhenReady=focus
    this.editForm.setValue({
      name:row.name,
      description:row.description
    })
    this.editForm.markAsPristine()
  }
  async editSave(){
    if(!this.currentlyEditing) throw new Error("should never be undefined")
    const row=this.currentlyEditing
    if(this.editForm.invalid) {
        this.editForm.markAllAsTouched()
        return
    }
    const form=this.editForm.value
    row.name=form.name
    row.description=form.description
    this.currentlyEditing=undefined

    this.saving.select(row)
    await this.adminS.editExpenseType({
      id:row.id,
      deprecated:row.deprecated,
      ...form,
    }).toPromise()
    this.saving.deselect(row)
  }

  editCancel(){
    this.currentlyEditing=undefined
  }
  selectEdit(f:ElementRef<HTMLInputElement>,ifType:string){
    if(!f || this.focusWhenReady!=ifType) return;
    f.nativeElement.select()
    this.focusWhenReady=undefined
    this.cdRef.detectChanges()
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean): any {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
