import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface button<R>{
  name:string
  action(ref: MatDialogRef<DialogGeneralData,R>): void | Promise<void>
  type?:"raised"
  color?:string
}
export interface DialogGeneralData<R=any> {
    title: string
    text: string
    buttons: button<R>[]
}
@Component({
    selector: 'app-dialog-general',
    templateUrl: './dialog-general.component.html',
    styleUrls: ['./dialog-general.component.scss']
})
export class DialogGeneralComponent implements OnInit {
    waits:{[k:string]:boolean}={}
    get waiting(){
        return Object.entries(this.waits).some(v=>v)
    }
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogGeneralData,
        public ref: MatDialogRef<DialogGeneralData,any>
    ) { }

    ngOnInit(): void {
    }
    async call(button:button<any>){
        this.waits[button.name]=true
        await button.action(this.ref)
        this.waits[button.name]=false
    }
}
