<mat-toolbar>
  <span>Backoffice: Neuer Spesentyp erfassen</span>
  <span class="actionbar-spacer"></span>
  <button class="icon-button" mat-icon-button (click)="saveExpenseType()">
    <mat-spinner *ngIf="saving" diameter="40"></mat-spinner>
    <mat-icon *ngIf="!saving" matTooltip="Speichern">done</mat-icon>
  </button >
  <button class="icon-button" mat-icon-button>
    <mat-icon routerLink="/overview" matTooltip="Abbrechen">close</mat-icon>
  </button>
  <button class="icon-button" mat-icon-button>
    <mat-icon matTooltip="Löschen">delete</mat-icon>
  </button>
</mat-toolbar>

<mat-card>
  <form [formGroup]="expenseTypeForm">
    <mat-form-field class="taxtype-input" appearance="standard">
      <mat-label>Name</mat-label>
      <input matInput appearance="outline" formControlName="name" />
    </mat-form-field>

    <mat-form-field class="taxtype-input description" appearance="standard">
      <mat-label>Beschreibung</mat-label>
      <textarea matInput appearance="outline" formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Bexio Kontonummer</mat-label>
      <input matInput appearance="outline" formControlName="accountId" />
    </mat-form-field>

    <p>Standard Mehrwertsteuer</p>
    <mat-radio-group formControlName="defaultVat" class="new-radio-group" aria-label="Select an option">
      <mat-radio-button value="no receipt">Keine Quittung</mat-radio-button>
      <mat-radio-button [value]="0">0%</mat-radio-button>
      <mat-radio-button [value]="2.6">2.6%</mat-radio-button>
      <mat-radio-button [value]="3.8">3.8%</mat-radio-button>
      <mat-radio-button [value]="8.1">8.1%</mat-radio-button>
    </mat-radio-group>
  </form>
</mat-card>
