import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SpesenFormComponent } from './spesen-form/spesen-form.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { OverviewComponent } from './overview/overview.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSortModule } from '@angular/material/sort';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { NewExpensetypeComponent } from './new-expensetype/new-expensetype.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpensetypeOverviewComponent } from './expensetype-overview/expensetype-overview.component';
import { apiGatewayHttpClientParts } from './httpClients/apiGateway.client';
import { AuthGuard } from './authentication/auth.guard';
import { HttpClientModule } from '@angular/common/http';

import { DatePipe } from '@angular/common';
import { DialogGeneralComponent } from './dialog-general/dialog-general.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogCloseMonthComponent } from "./dialog-close-month/dialog-close-month.component"

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SpesenFormComponent,
    OverviewComponent,
    BackofficeComponent,
    NewExpensetypeComponent,
    ExpensetypeOverviewComponent,

    DialogGeneralComponent,
    DialogCloseMonthComponent,
    DeleteDialogComponent,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,

    MatToolbarModule,
    MatMenuModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatCardModule,
    MatProgressSpinnerModule,

    FormsModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
  ],
  providers: [
    AuthGuard,
    MatDatepickerModule,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    apiGatewayHttpClientParts,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
