import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

export interface UserDetail {
    userName: string;
    email: string;
    given_name: string;
    family_name: string;
    roles:Set<string>
}

export enum ApplicationStatus {
    NOTAUTHENTICATED,
    AUTHENTICATED,
}
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private userSubj = new ReplaySubject<Partial<UserDetail> | null>(1);
    get user$():Observable<Partial<UserDetail>|null>{ return this.userSubj }
    private applicationStatusSubj = new ReplaySubject<ApplicationStatus>(1);
    get applicationStatus$():Observable<ApplicationStatus>{ return this.applicationStatusSubj }
    constructor(
      private route:ActivatedRoute,
      private router:Router
    ) {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                this.updateUserDetail(user);
              console.log("AUTH")
                this.applicationStatusSubj.next(ApplicationStatus.AUTHENTICATED);
            })
            .catch(() => {
              console.log("NOAUTH")
                this.applicationStatusSubj.next(ApplicationStatus.NOTAUTHENTICATED);
                this.userSubj.next(null)
            });
    }

    async getIdToken(): Promise<string> {
        const session=await Auth.currentSession()
        return session.getIdToken().getJwtToken()
    }

    logout(): void {
        Auth.signOut();
        console.log("NOAUTH")
        this.applicationStatusSubj.next(ApplicationStatus.NOTAUTHENTICATED);
        this.userSubj.next(null);
    }

    async openFederatedLogin() {
        localStorage.setItem("authLastRoute",location.pathname+location.search+location.hash)
        await Auth.federatedSignIn({ customProvider: 'aad' });
    }
    async handleLastRoute(){
        const route=localStorage.getItem("authLastRoute")
        if(route!=null){
          localStorage.removeItem("authLastRoute")
          await this.router.navigateByUrl(route)
        }
    }
    async hasRole(role:string){
        const user=await this.userSubj.pipe(first()).toPromise()
        return !!user?.roles?.has(role)
    }
    private updateUserDetail(cognitoUser: CognitoUser) {
        cognitoUser.getUserAttributes((error, attributes) => {
            if (error || !attributes) {
                console.log("failedUserUpdate")
                return
            }
            const attrMap=new Map(attributes.map(v=>[v.Name,v.Value]))
            /*
            `custom:roles` is either formatted like `[Employee, Hr]` or `Hr` or completely
            missing.
            this handles all those cases
            */
            const rolesS=attrMap.get("custom:roles")?.startsWith("[")
              //@ts-ignore
              ? attrMap.get("custom:roles").slice(1,-1)
              : attrMap.get("custom:roles")

            const userDetail:Partial<UserDetail> = {
                email: attrMap.get("email"),
                family_name: attrMap.get('family_name'),
                given_name: attrMap.get('given_name'),
                roles: rolesS
                    ? new Set(rolesS.split(",").map(v=>v.trim()))
                    : new Set()
            }
            this.userSubj.next(userDetail);
        });
    }

}
