<mat-toolbar>
  <span>Übersicht der Spesentypen</span>
  <span class="actionbar-spacer"></span>
  <button mat-raised-button routerLink="/new-expense-type">Neuer Spesentyp</button>
</mat-toolbar>

<mat-card>
  <table
    matSort
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 overview-table"
    (matSortChange)="sortData($event)"
    aria-describedby="Overview Table"
    [formGroup]="editForm"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAll() : null"
          [checked]="selection.hasValue() && allSelected"
          [indeterminate]="selection.hasValue() && !allSelected"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th id="name" mat-header-cell mat-sort-header="name" *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" (click)="edit(element,'name')" [class.editing]="currentlyEditing==element">
        <ng-container *ngIf="currentlyEditing!=element; else editForm">
        <span>{{ element.name }}</span>
        </ng-container>
        <ng-template #editForm>
          <mat-form-field>
            <input matInput #nameField formControlName="name">
            <mat-error>missing field name</mat-error>
          </mat-form-field>
        </ng-template>
        <mat-icon class="edit-icon">edit</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="taxType">
      <th id="taxType" mat-header-cell mat-sort-header="taxType" *matHeaderCellDef>Standard Steuersatz</th>
      <td mat-cell *matCellDef="let element">{{ element.defaultVat=="no receipt" ? "Keine Quittung" : element.defaultVat+"%" }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th id="description" mat-header-cell mat-sort-header="description" *matHeaderCellDef>Beschreibung</th>
      <td mat-cell *matCellDef="let element" (click)="edit(element,'description')" [class.editing]="currentlyEditing==element">
        <ng-container *ngIf="currentlyEditing!=element; else editForm">
          <span>{{ element.description }}</span>
        </ng-container>
        <ng-template #editForm>
          <mat-form-field>
            <input matInput #descriptionField formControlName="description">
          </mat-form-field>
        </ng-template>
        <mat-icon class="edit-icon">edit</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="bexioCustomerNumber">
      <th id="bexioCustomerNumber" mat-header-cell mat-sort-header="bexioCustomerNumber" *matHeaderCellDef>
        Bexio Kontonummer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.accountId }}</td>
    </ng-container>
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef class="menuColumn">
        <button mat-icon-button
          class="menuButton"
          [disabled]="!selection.hasValue()"
          [matMenuTriggerFor]="itemMenu"
        ><mat-icon>more_vert</mat-icon></button>
      </th>
      <td mat-cell *matCellDef="let row" class="menuColumn" [class.editing]="currentlyEditing">
        <mat-spinner *ngIf="saving.isSelected(row)" diameter="40"></mat-spinner>
        <ng-container *ngIf="currentlyEditing==row">
          <button mat-icon-button (click)="editSave()"><mat-icon>save</mat-icon></button>
          <button mat-icon-button (click)="editCancel()"><mat-icon>cancel</mat-icon></button>
        </ng-container>
        <button mat-icon-button
          *ngIf="currentlyEditing!=row && !saving.isSelected(row)"
          class="menuButton"
          [matMenuTriggerFor]="itemMenu"
          [matMenuTriggerData]="{row:row}"
        ><mat-icon>more_vert</mat-icon></button>
      </td>
      <mat-menu #itemMenu="matMenu">
        <ng-container *matMenuContent="let row=row">
          <button mat-menu-item (click)="delete(row)">Delete</button>
          <button mat-menu-item *ngIf="row" (click)="edit(row)">Edit</button>
        </ng-container>
      </mat-menu>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.editing]="currentlyEditing==row"></tr>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</mat-card>
