import { CurrencyPipe, DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import { ListExpense } from './types';
import { getTaxAmount } from "./util"

export function exportExpensesSinglePerson(expenses: ListExpense[]): void {
  let doc = new jsPDF();
  const datePipe = new DatePipe('en');
  const currencyPipe = new CurrencyPipe('en-USD');

  // Set title and username
  doc.setFontSize(11);
  doc.setFont('Helvetica', 'bold');
  doc.text('Employee:', 20, 20);
  doc.text(expenses[0].user.displayName, 45, 20);

  // Set date
  doc.setFontSize(8);

  const firstAndLastDay = getFirstAndLastDay(expenses);
  doc.text('Period:', 20, 30);
  doc.text(
    datePipe.transform(firstAndLastDay[0], 'yyyy-MM-dd') +
      ' - ' +
      datePipe.transform(firstAndLastDay[1], 'yyyy-MM-dd'),
    45,
    30
  );

  // Table head
  doc.text('Date:', 20, 40);
  doc.text('Description:', 45, 40);
  doc.text('Amount:', 120, 40);
  doc.text('MwSt:', 140, 40);
  doc.text('MwSt %:', 160, 40);
  doc.text('Receipt:', 175, 40);

  // Set dynamic content
  doc.setFont('Helvetica', '');

  let yCoordinate = 45;
  let totalAmount = 0;
  let totalTaxAmount = 0;
  expenses.forEach((expense) => {
    const vatNumber= typeof expense.vat=="number" ? expense.vat : 0

    let taxAmount = roundCHF(getTaxAmount(vatNumber,expense.amount))
    if (!expense.vat) {
      taxAmount = 0;
      expense.vat = 0;
    }

    // Limit description length to prevent overflow
    let description = expense.description;
    if (expense.description.length > 50) {
      description = expense.description.slice(0, 50) + '...';
    }
    //date
    doc.text(datePipe.transform(expense.date, 'yyyy-MM-dd (EEEEEE)') ?? "", 20, yCoordinate);
    //description
    doc.text(description, 45, yCoordinate);
    //amount
    doc.text(String(currencyPipe.transform(expense.amount, 'CHF')), 120, yCoordinate);
    //mwst
    doc.text(String(currencyPipe.transform(taxAmount, 'CHF')), 140, yCoordinate);
    //mwst %
    doc.text(String(vatNumber + ' %'), 160, yCoordinate);

    // Set color and text of attachment
    if (Object.keys(expense.attachments).length > 0) {
      doc.setTextColor(0, 0xc8, 0);
      doc.text('Yes', 177, yCoordinate);
    } else {
      doc.setTextColor(255, 0, 0);
      doc.text('No', 178, yCoordinate);
    }
    doc.setTextColor(0, 0, 0);

    doc.line(20, yCoordinate + 1, 190, yCoordinate + 1);

    totalAmount += expense.amount;
    totalTaxAmount += taxAmount;
    yCoordinate += 5;
  });
  doc.setTextColor(0, 0, 0);

  doc.setFont('Helvetica', 'bold');

  // Set total amount
  const totalAmountFormatted = currencyPipe.transform(totalAmount, 'CHF');
  const totalTaxAmountFormatted = currencyPipe.transform(totalTaxAmount, 'CHF');

  yCoordinate += 5;
  doc.text('Total', 45, yCoordinate);
  doc.line(120, yCoordinate - 4, 160, yCoordinate - 4);
  doc.text(String(totalAmountFormatted), 120, yCoordinate);
  doc.line(120, yCoordinate + 1, 160, yCoordinate + 1);
  doc.text(String(totalTaxAmountFormatted), 140, yCoordinate);

  doc.save(expenses[0].user.displayName + '_expenses.pdf');
}

export function exportExpensesAllEmployees(expenses: ListExpense[],filters:any): void {
  const doc = new jsPDF({orientation:"landscape"});
  const datePipe = new DatePipe('en');
  const currencyPipe = new CurrencyPipe('en-USD');
  // Set title and username
  doc.setFontSize(11);
  doc.setFont('Helvetica', 'bold');
  if(filters.user){
    doc.text(`Expenses of ${filters.user.displayName}`,20,20)
  }else{
    doc.text('Expenses for all Employees:', 20, 20);
  }

  // Set date
  doc.setFontSize(8);

  const firstAndLastDay = getFirstAndLastDay(expenses);
  doc.text('Period:', 20, 30);
  doc.text(
    datePipe.transform(firstAndLastDay[0], 'yyyy-MM-dd') +
      ' - ' +
      datePipe.transform(firstAndLastDay[1], 'yyyy-MM-dd'),
    45,
    30
  );

  // Table head
  /** vertical coord*/
  let y=40
  /** horizontal coord */
  let x=20
  doc.text('Date:', x, y);
  doc.text('Person:', x+=25, y);
  doc.text('Amount:', x+=40, y);
  doc.text('MwSt:', x+=25, y);
  doc.text('MwSt %:', x+=25, y);
  doc.text('Receipt:', x+=15, y);
  doc.text('Description:', x+=15, y);

  // Set dynamic content
  doc.setFont('Helvetica', '');

  y += 5;
  let totalAmount = 0;
  let totalTaxAmount = 0;
  expenses.forEach((expense) => {
    const vatNumber= typeof expense.vat=="number" ? expense.vat : 0
    const taxAmount = roundCHF(getTaxAmount(vatNumber,expense.amount))
    // Limit description length to prevent overflow
    let description = expense.description;
    if (expense.description.length > 65) {
      description = expense.description.slice(0, 65) + '...';
    }
    x=20
    //date
    doc.text(datePipe.transform(expense.date, 'yyyy-MM-dd (EEEEEE)') ??"", x, y);
    //Person
    doc.text(expense.user.displayName, x+=25, y);
    //Amount
    doc.text(currencyPipe.transform(expense.amount, 'CHF') ?? "", x+=40, y);
    //Mwst
    doc.text(currencyPipe.transform(taxAmount, 'CHF') ?? "", x+=25, y);
    //Mwst %
    doc.text(vatNumber + ' %', x+=25, y);

    // Set color and text of attachment
    x+=15
    if (Object.keys(expense.attachments).length > 0) {
      doc.setTextColor(0, 0xc8, 0);
      doc.text('Yes', x+2, y);
    } else {
      doc.setTextColor(255, 0, 0);
      doc.text('No', x+3, y);
    }
    doc.setTextColor(0, 0, 0);
    //Description
    doc.text(description, x+=15, y);

    doc.line(20, y + 1, 277 , y + 1);

    totalAmount += expense.amount;
    totalTaxAmount += taxAmount;
    y += 5;
  });
  doc.setTextColor(0, 0, 0);

  doc.setFont('Helvetica', 'bold');

  // Set total amount
  const totalAmountFormatted = currencyPipe.transform(totalAmount, 'CHF');
  const totalTaxAmountFormatted = currencyPipe.transform(totalTaxAmount, 'CHF');

  y += 5;
  x=45
  doc.text('Total', x, y);
  x+=40
  doc.line(x, y - 4, x+50, y - 4);
  doc.text(String(totalAmountFormatted), x, y);
  doc.line(x, y + 1, x+50, y + 1);
  doc.text(String(totalTaxAmountFormatted), x+=25, y);

  doc.save('expenses.pdf');
}

function roundCHF(decimal: number): number {
  return Math.round(decimal * 20) / 20;
}

function getFirstAndLastDay(expenses: ListExpense[]): string[] {
  expenses.sort((a, b)=>a.date.localeCompare(b.date));

  const start=new Date(expenses[0].date)
  start.setDate(1)

  const end=new Date(expenses[expenses.length-1].date)
  end.setMonth(end.getMonth()+1)
  end.setDate(0)

  return [start.toISOString(), end.toISOString()];
}
