<h1 mat-dialog-title></h1>
<div mat-dialog-content>
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Spesendatum</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="dateControl" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="waiting" (click)="save()">
        close Months
        <mat-spinner *ngIf="waiting" color="accent" class="start-spinner" diameter="25" class="button-spinner"></mat-spinner>
    </button>
    <button mat-button [disabled]="waiting" (click)="cancel()">
        Cancel
    </button>
</div>
