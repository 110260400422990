import { FormGroup as _FormGroup, FormArray as _FormArray, AbstractControl, ValidationErrors } from "@angular/forms";

export function createForm<T extends { [k: string]: AbstractControl }>(
    v:T,
    validators?: createForm.ValidFn<T> | createForm.ValidFn<T>[],
    asyncValidators?: createForm.AsyncValidFn<T> | createForm.AsyncValidFn<T>[]
):createForm.FormGroup<T>
export function createForm<T extends AbstractControl[]>(
    v:T,
    validators?: createForm.ValidFn<T> | createForm.ValidFn<T>[],
    asyncValidators?: createForm.AsyncValidFn<T> | createForm.AsyncValidFn<T>[]
):createForm.FormArray<T>
export function createForm<T extends AbstractControl[]|Record<string,AbstractControl>>(
    v: T,
    validators?: createForm.ValidFn<T> | createForm.ValidFn<T>[],
    asyncValidators?: createForm.AsyncValidFn<T> | createForm.AsyncValidFn<T>[]
){
    if(v instanceof Array){
      return new _FormArray(v, validators as any, asyncValidators as any) as any
    }else{
      return new _FormGroup(v as any, validators as any, asyncValidators as any) as any
    }
}
export namespace createForm {
    export interface FormArray<T extends AbstractControl[]> extends _FormArray{
       controls:T,
       value: {
           [K in keyof T]: T[K] extends { value: infer U } ? U : void
       }
    }
    export interface FormGroup<T extends { [k: string]: AbstractControl }> extends _FormGroup {
        controls: T,
        value: {
            [K in keyof T]: T[K] extends { value: infer U } ? U : void
        }
    }
    export type ValidFn<T extends { [k: string]: AbstractControl }|AbstractControl[]> = (control: T extends AbstractControl[]
      ? FormArray<T>
      : T extends Record<string,AbstractControl>
      ? FormGroup<T>
      : never) => ValidationErrors
    export type AsyncValidFn<T extends { [k: string]: AbstractControl }|AbstractControl[]> = (control: T extends AbstractControl[]
      ? FormArray<T>
      : T extends Record<string,AbstractControl>
      ? FormGroup<T>
      : never) => Promise<ValidationErrors>
}


export function getTaxAmount(taxInPercent: number | string, amount: number): number {
  if (typeof taxInPercent == 'string' || !taxInPercent || !amount) {
    return 0;
  }
  return taxInPercent * amount / (100+taxInPercent);
}
