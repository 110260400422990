import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { NewExpensetypeComponent } from './new-expensetype/new-expensetype.component';
import { AuthGuard } from './authentication/auth.guard';
import { OverviewComponent } from './overview/overview.component';
import { SpesenFormComponent } from './spesen-form/spesen-form.component';
import { ExpensetypeOverviewComponent } from './expensetype-overview/expensetype-overview.component';
const admin=true
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'overview' },
      { path: 'new-expense-type', component: NewExpensetypeComponent },
      { path: 'new', component: SpesenFormComponent },
      { path: 'overview', component: OverviewComponent },
      { path: 'details/:id', component: SpesenFormComponent },
    ],
  },
  {
    path: '',
    canActivate:[AuthGuard],
    data:{auth:{role:"HR"}},
    children:[
      { path: 'backoffice', component: BackofficeComponent },
      { path: 'expense-type-overview', component: ExpensetypeOverviewComponent },
      { path: 'details/:user/:id' , component: SpesenFormComponent, data:{admin} },
      { path: 'newAdmin', component: SpesenFormComponent, data:{admin} }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
