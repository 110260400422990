<mat-toolbar>
  <span>{{ isDetailMode ? 'Vorhandene Spesen' : 'Neue Spesen erfassen' }}</span>
  <span class="actionbar-spacer"></span>
  <button mat-icon-button class="icon-button" *ngIf="!isDetailMode" matTooltip="Speichern" (click)="createNewExpense()" [disabled]="progress!=undefined">
    <ng-container *ngIf="progress">
      <mat-progress-spinner
        diameter="40"
        [value]="p.progress * 100"
        *ngIf="progress | async as p; else ProgElse"
      ></mat-progress-spinner>
      <ng-template #ProgElse>
        <mat-spinner diameter="40"></mat-spinner>
      </ng-template>
    </ng-container>
    <mat-icon *ngIf="!progress" >done</mat-icon>
  </button>
  <button mat-icon-button class="icon-button" *ngIf="isDetailMode" matTooltip="Speichern" (click)="patchExpense()" [disabled]="!!progress">
    <ng-container *ngIf="progress">
      <mat-progress-spinner
        diameter="40"
        [value]="p.progress * 100"
        *ngIf="progress | async as p; else ProgElse"
      ></mat-progress-spinner>
      <ng-template #ProgElse>
        <mat-spinner diameter="40"></mat-spinner>
      </ng-template>
    </ng-container>
    <mat-icon *ngIf="!progress" >done</mat-icon>
  </button>
  <button  mat-icon-button class="icon-button" *ngIf="!isDetailMode" matTooltip="Abbrechen" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <button class="icon-button" *ngIf="isDetails && !expenseForm.controls['payed'].value" mat-icon-button matTooltip="Löschen" (click)="deleteExpense(currentId)">
    <mat-icon>delete</mat-icon>
  </button>
</mat-toolbar>

<mat-card>
  <form [formGroup]="expenseForm">
    <div class="add-wrapper">
      <div class="left-cont">
        <mat-form-field class="full-width" *ngIf="isAdmin">
          <mat-label>User</mat-label>
          <mat-select [disabled]="isDetails" formControlName="user">
            <mat-option *ngFor="let u of users" [value]="u.id">{{u.displayName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Spesendatum</mat-label>
          <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="standard">
          <mat-label>Spesentyp</mat-label>
          <mat-select formControlName="typeId">
            <mat-option disabled *ngIf="!expensetypes?.length"
              ><mat-progress-bar mode="indeterminate"></mat-progress-bar
            ></mat-option>
            <mat-option *ngFor="let expensetype of expensetypes" [value]="expensetype.id">
              {{ expensetype.name }}
            </mat-option>
            <mat-option *ngIf="oldExpenseType" disabled [value]="oldExpenseType.id">
              {{ oldExpenseType.name }} [OLD]
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container formArrayName="sums">
          <div *ngFor="let sum of sumControls;let i=index" [formGroup]="sum" class="sumRow">
            <mat-form-field class="vatField">
              <mat-label>MwSt.</mat-label>
              <mat-select formControlName="vat">
                <mat-option value="no receipt" *ngIf="sumControls.length==1">Keine Quittung</mat-option>
                <mat-option [value]="0">0%</mat-option>
                <mat-option *ngFor="let radioButtons of radioButtonsTax" [value]="radioButtons"
                  >{{ radioButtons }}%</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field class="amountField">
              <mat-label>Betrag</mat-label>
              <p matPrefix>CHF</p>
              <input matInput type="number" min="0" appearance="outline" formControlName="amount" />
            </mat-form-field>
            <button mat-icon-button *ngIf="sumControls.length>1 && isEditable" (click)="removeVat(i)"><mat-icon>remove</mat-icon></button>
          </div>
        </ng-container>
        <button mat-icon-button *ngIf="isEditable" (click)="addVat()"><mat-icon>add</mat-icon></button>
        <!-- Is calculated automatically (amount / 100 * taxType) -->
        <div class="flex">
          <mat-form-field class="full-width">
            <mat-label>Total MwSt</mat-label>
            <input matInput readonly [value]="vatAmount | currency:'CHF'" disabled/>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Total</mat-label>
            <input matInput readonly [value]="amount | currency:'CHF'" disabled/>
          </mat-form-field>
        </div>

        <!-- Only available for Backoffice -->
        <mat-checkbox formControlName="payed">Bezahlt</mat-checkbox>
      </div>

      <div class="right-cont">
        <mat-form-field class="full-width" appearance="standard">
          <mat-label>Beschreibung</mat-label>
          <textarea matInput appearance="outline" formControlName="description"></textarea>
        </mat-form-field>

        <mat-checkbox formControlName="passOn">Spesen weiterverrechnen</mat-checkbox>

        <mat-form-field class="full-width" appearance="standard" *ngIf="isProceedExpenses()">
          <mat-label>Kunde</mat-label>
          <mat-select formControlName="customer">
            <mat-option value="Felix Campione">Felix Campione</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="standard" *ngIf="isProceedExpenses()">
          <mat-label>Projekt</mat-label>
          <mat-select formControlName="project">
            <mat-option value="Cooles Projekt">Cooles Projekt</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <h2>Belege</h2>
    <div *ngIf="isEditable">
      <mat-toolbar class="fileSelectBar" (click)="fileInput.click()">
        <!-- Display files names -->
        <input matInput [value]="attachmentError || fileAttr" [class.error]="attachmentError" readonly name="name" />

        <!-- Browse Button -->
        <button mat-icon-button *ngIf="selectedFiles.length" (click)="clearFiles();$event.stopPropagation()"><mat-icon>delete</mat-icon></button>
        <button mat-flat-button class="file-upload-button" color="warn">Dateien wählen</button>
      </mat-toolbar>

      <!-- Fetch selected filed on change -->
      <input
        type="file"
        #fileInput
        id="uploadFile"
        (change)="uploadFileEvt($event)"
        name="uploadFile"
        multiple="multiple"
      />
    </div>
  </form>
  <div
    *ngFor="
      let attachment of (this.expenseForm.controls.attachments && this.expenseForm.controls.attachments.value) || []
    "
  >
    <div *ngIf="isImage(attachment.name)">
      <img class="attachment-image" src="{{ attachment.url }}" alt="attachment" />
    </div>
    <button mat-button (click)="downloadAttachment(attachment)">{{ attachment.name }} herunterladen</button>
    <button mat-button *ngIf="isEditable" (click)="deleteAttachment(attachment)">
      {{ attachment.name }} entfernen
    </button>
  </div>
</mat-card>
