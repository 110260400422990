import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { InjectionToken, Injector } from "@angular/core"
import { Observable } from "rxjs"

export class InterceptorHandler implements HttpHandler{
    constructor(
        private interceptor:HttpInterceptor,
        private next:HttpHandler
    ){}
    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(req,this.next)
    }

}
export function CustomHttpClient(token:InjectionToken<HttpInterceptor[]>){
    return class CustomHttpClient extends HttpClient{
        constructor(injector:Injector){
            const backend=injector.get(HttpBackend)
            const interceptors=injector.get(token)

            const handlers=interceptors.reduceRight((next,interceptor)=>new InterceptorHandler(interceptor,next),backend)
            super(handlers)
        }
    }
}