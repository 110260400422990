import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from "@aws-amplify/core"
Amplify.configure({ Auth:{
    region: environment.region,
    userPoolId: environment.auth.userPoolId,
    userPoolWebClientId: environment.auth.clientId,
    oauth: {
        domain: environment.auth.identityProviderSettings.domainName,
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: environment.auth.identityProviderSettings.redirectUri,
        redirectSignOut: environment.auth.identityProviderSettings.redirectUri,
        responseType: 'code',
    },
} })
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
