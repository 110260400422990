import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, InjectionToken, Injector } from "@angular/core";
import { defer, Observable } from "rxjs";
import {switchMap} from "rxjs/operators"
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../authentication/authentication.service";
import { CustomHttpClient } from "./custom.client";

const API_GATEWAY_INTERCEPTORS=new InjectionToken<HttpInterceptor[]>("API_GATEWAY_INTERCEPTORS")
@Injectable()
export class ApiGatewayClient extends CustomHttpClient(API_GATEWAY_INTERCEPTORS){
    constructor(injector: Injector){super(injector)}
}

@Injectable()
export class ApiGatewayInterceptor implements HttpInterceptor{
    constructor(
        private auth:AuthenticationService
    ){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return defer(async ()=>{
            const auth=await this.auth.getIdToken()
            return next.handle(req.clone({
                url:`https://${environment.api}/${req.url}`,
                setHeaders:{ auth }
            }))
        }).pipe(switchMap(v=>v))
    }
}
export const apiGatewayHttpClientParts=[
    ApiGatewayClient,
    ApiGatewayInterceptor,
    {provide:API_GATEWAY_INTERCEPTORS,useClass:ApiGatewayInterceptor,multi:true},
]