import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSort, Sort } from '@angular/material/sort';
import { Moment } from 'moment';
import { ExpenseService } from '../services/expense.service';
import { Expense, ListExpense } from '../types';
import * as PDFtemplate from '../PDFtemplate';
import { ExpenseType, ExpenseWDownloads, Replace } from '../../backendTypes';
import { getTaxAmount } from '../util';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment"
export const MY_FORMATS = {
  display: {
    dateInput: 'MM.YYYY',
  },
};

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class OverviewComponent implements OnInit {
  @ViewChild(MatSort) matSort: MatSort;
  get total(){
    return this.dataSource.map(v=>v.amount)
      .reduce((o,c)=>o+c,0)
  }
  get totalVat(){
    return this.dataSource.map(v=>v.vatAmount)
      .reduce((o,c)=>o+c,0)
  }
  sortedData: Expense[];
  expenseTypes=new Map<string,ExpenseType>()

  displayedColumns: string[] = ['date', 'typeId', 'amount', 'vat', 'vatAmount', 'payed', 'attachments'];
  totalExpenses = 100;
  unfilteredDataSource: ListExpense[];
  dataSource: ListExpense[] = [];

  typeIdFilter?: string;
  dateFilter?: Moment;
  openExpensesFilter: boolean;
  vatFilter?: number;

  avalilableExpensTypes: ExpenseType[];

  vatTypes = [0, 2.6, 3.8, 8.1];
  loading=true
  exportPDFthrobber=false

  constructor(
    private adapter: DateAdapter<any>,
    private api: ExpenseService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.adapter.setLocale('de');
  }
  getTaxAmount=getTaxAmount;
  ngOnInit(): void {
    this.api.listExpenses().subscribe(async (expenses) => {
      this.loadFilterSort()
      this.dataSource = expenses;
      this.unfilteredDataSource = this.dataSource;
      this.avalilableExpensTypes = await this.getAvailableExpenseTypes();
      this.loading=false
      this.filterData()
      this.sortData(this.matSort)
    });

    this.api.listExpenseTypes().toPromise().then(types=>{
      types.forEach(v=>this.expenseTypes.set(v.id,v))
    })
  }
  loadFilterSort(){
    const map=this.route.snapshot.queryParamMap
    this.dateFilter=map.has("filDate") ? moment(map.get("filDate")) : undefined
    this.typeIdFilter=map.get("filType") ?? undefined
    this.vatFilter=map.has("filVat") ? Number(map.get("filVat")) : undefined
    this.openExpensesFilter=map.has("filOpen")
    this.matSort.sort({
      id:map.get("sortId") ?? "date",
      start:map.get("sortDir") as any ?? "asc",
      disableClear:false
    })
  }
  sortData(sort: Sort): any {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') return;
    const key:keyof ListExpense=sort.active as any
    data.sort((a, b) => {
      const flip=sort.direction=="asc" ? -1 : 1
      switch (key) {
        case 'attachments':
          return (Object.keys(a.attachments).length - Object.keys(b.attachments).length)*flip
        default:
          if(typeof a[key]=="number" || typeof a[key]=="boolean"){
            return (<number>a[key]-<number>b[key])*flip
          }else if(typeof a[key]=="string"){
            return (a[key] as string).localeCompare(b[key] as string)*flip
          }else{
            return 0
          }
      }
    });
    this.dataSource = data;
    this.updateQueryParams()
  }

  closeDatePicker(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.dateFilter = normalizedMonth;
    datepicker.close();
    this.filterData();
  }
  updateQueryParams(){
    const sortDefault=this.matSort.direction=="asc" && this.matSort.active=="date"
    this.router.navigate([],{
      replaceUrl:true,
      queryParamsHandling:"merge",
      queryParams:{
        filType:this.typeIdFilter,
        filDate:this.dateFilter && this.datePipe.transform(this.dateFilter.toDate(), 'yyyy-MM'),
        filVat:this.vatFilter,
        filOpen:this.openExpensesFilter ? "" : undefined,
        sortDir:!sortDefault ? this.matSort.direction : undefined,
        sortId:!sortDefault ? this.matSort.active : undefined
      }
    })
  }
  filterData(): void {
    this.dataSource = this.unfilteredDataSource;

    this.dataSource = this.dataSource
      .filter((element) => !this.typeIdFilter || element.typeId==this.typeIdFilter)
      .filter((element) => !this.dateFilter ||
          this.datePipe.transform(element.date, 'yyyy-MM') ==
          this.datePipe.transform(this.dateFilter.toDate(), 'yyyy-MM'))
      .filter((element) => this.vatFilter==undefined || element.vat == this.vatFilter)
      .filter((element) => !this.openExpensesFilter || element.payed != this.openExpensesFilter);
    this.sortData(this.matSort)
  }
  resetDateFilter(){
    this.dateFilter=undefined
    this.filterData()
  }

  async getAvailableExpenseTypes(): Promise<ExpenseType[]> {
    const types=await this.api.listExpenseTypes().toPromise()
    return types.filter(v=>this.unfilteredDataSource.some(exp=>exp.typeId==v.id))
  }

  isData(): boolean {
    return this.dataSource.length > 0;
  }

  isAttachment(element: Expense): boolean {
    return Object.keys(element.attachments).length > 0 || Number(element.attachments.length) > 0;
  }

  async exportPDF() {
    this.exportPDFthrobber=true
    const list:ListExpense[]=await Promise.all(this.dataSource.map(async v=>{
      if(v.vat=="MULTIPLE"){
          const item=await this.api.getExpense(v.id).toPromise()
          return item.sums.map<ListExpense>(v=>({
            ...item,
            amount:v.amount,
            vat:v.vat!,
            vatAmount:typeof v.vat=="number" ? v.vat * v.amount : 0,
            attachments:item.attachments.length ? {PLACE:"HOLDER"} : {} as {}
          }))
      }else{
          return [v]
      }
    })).then(v=>v.reduce((o,c)=>o.concat(c),[]))
    PDFtemplate.exportExpensesSinglePerson(list);
    this.exportPDFthrobber=false
  }
  attachmentCache=new Map<string,Replace<ExpenseWDownloads,{user:any}>>()
  async getAttachment({id}:Expense,attachm:string){
    const key=id
    let exp=this.attachmentCache.get(key)
    if(!exp){
      exp=await this.api.getExpense(id).toPromise()
      this.attachmentCache.set(key,exp)
      if(this.attachmentCache.size>10){
        this.attachmentCache.delete(this.attachmentCache.keys().next().value)
      }
    }
    const url=exp.attachments.find((v:any)=>v.hash==attachm)!.url
    window.location.href = url;
  }
}
